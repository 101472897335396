<template>
    <div>
        <div class="demo-spin-article">
            <ts-panel class=" note alert alert-primary fade show tw-rounded-md">
                <ts-panel-wrapper>
                    <div>
                        <label>{{
                            $t('basicSalaryFormulaParameter.parameter')
                        }}</label>
                        <Tooltip placement="top">
                            <i
                                class="fas fa-question-circle  tw-ml-3 has-tooltip"
                            ></i>
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Use this parameters to create formula
                                </div>
                            </div>
                        </Tooltip>
                        <div class="mb-3">
                            <template>
                                <Tooltip placement="top">
                                    <a
                                        class="tw-p-2 tw-no-underline tw-text-black tw-text-xs"
                                        v-for="(basicSalaryFormulaParameter,
                                        index) in basicSalaryFormulaParameters"
                                        :key="index"
                                        v-clipboard:copy="
                                            basicSalaryFormulaParameter.param_name
                                        "
                                        @click="copied"
                                        :value="
                                            basicSalaryFormulaParameter.param_name
                                        "
                                        >{{
                                            basicSalaryFormulaParameter.param_name
                                        }}</a
                                    >
                                    <div slot="content">
                                        <div class="tw-text-xs">
                                            Click to copy parameter
                                        </div>
                                    </div>
                                </Tooltip>
                            </template>
                        </div>
                    </div>
                </ts-panel-wrapper>
            </ts-panel>
            <div class="mb-3 row">
                <label class="form-label col-label col-md-4 mt-1 required">{{
                    $t('basicSalaryFormulaParameter.salaryPerDay')
                }}</label>
                <div class="col-md-8">
                    <input
                        v-model="model.salary_per_day"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('salary_per_day')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('salary_per_day')"
                    >
                        {{ errors.first('salary_per_day') }}
                    </div>
                    <i class=" tw-text-xs"
                        >( e.g {base_salary} * {total_days} )</i
                    >
                </div>
            </div>
            <div class="mb-3 row">
                <label class="form-label col-label col-md-4 mt-1 required">{{
                    $t('basicSalaryFormulaParameter.salaryPerHours')
                }}</label>
                <div class="col-md-8">
                    <input
                        v-model="model.salary_per_hour"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('salary_per_hour')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('salary_per_hour')"
                    >
                        {{ errors.first('salary_per_hour') }}
                    </div>
                    <i class="tw-text-xs"
                        >( e.g {base_salary} * {total_hours} )</i
                    >
                </div>
            </div>
            <div class="row mb-3">
                <label class="form-label col-label col-md-4">{{
                    $t('basicSalaryFormulaParameter.endOfMonth')
                }}</label>
                <div class="col-md-8">
                    <Checkbox
                        v-model="model.is_end_of_month"
                        @on-change="
                            () => (model.total_days = model.total_hours = null)
                        "
                    ></Checkbox>
                </div>
            </div>
            <div class="row mb-3">
                <label
                    class="form-label col-label col-md-4 "
                    :class="{ required: !model.is_end_of_month }"
                    >{{ $t('basicSalaryFormulaParameter.totalDays') }}</label
                >
                <div class="col-md-8">
                    <input
                        v-model="model.total_days"
                        :disabled="model.is_end_of_month"
                        type="number"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('total_days')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('total_days')"
                    >
                        {{ errors.first('total_days') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="form-label col-label col-md-4 "
                    :class="{ required: !model.is_end_of_month }"
                    >{{ $t('basicSalaryFormulaParameter.totalHours') }}</label
                >
                <div class="col-md-8">
                    <input
                        v-model="model.total_hours"
                        :disabled="model.is_end_of_month"
                        type="number"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('total_hours')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('total_hours')"
                    >
                        {{ errors.first('total_hours') }}
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapState, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'basic-salary-formula-form',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                salary_per_day: null,
                salary_per_hour: null,
                is_end_of_month: null,
                total_days: null,
                total_hours: null
            }
        }
    },
    computed: {
        ...mapState('humanResource/basicSalaryFormula', [
            'edit_data',
            'basicSalaryFormulaParameters'
        ])
    },
    methods: {
        ...mapActions('humanResource/basicSalaryFormula', [
            'getBasicSalaryFormulaParameter'
        ]),
        fetchResource () {
            this.loading = true
            this.getBasicSalaryFormulaParameter()
                .then(() => {
                    this.setEditData()
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        copied () {
            this.$Message.success({ content: 'Copied', duration: 0.5 })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('humanResource/basicSalaryFormula/update', {
                    id: this.edit_data.shift_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.salary_per_day = this.edit_data.salary_per_day
                this.model.salary_per_hour = this.edit_data.salary_per_hour
                this.model.is_end_of_month = this.edit_data.is_end_of_month
                this.model.total_days = this.edit_data.total_days
                this.model.total_hours = this.edit_data.total_hours
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.salary_per_day = null
            this.model.salary_per_hour = null
            this.model.total_days = null
            this.model.total_days = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BASIC SALARY FORMULA',
                desc: not.text
            })
        }
    }
}
</script>
